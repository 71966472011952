import React, { useContext } from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { ToastContext } from "../common/ToastContext"
import { Container } from "../global"
import { CircularProgress } from "@material-ui/core"
const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      file(
        sourceInstanceName: { eq: "product" }
        name: { eq: "green-skew-pills-rendered" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  const [email, setEmail] = React.useState("")
  const [error, setError] = React.useState(false)
  const [submissionPending, setSubmissionPending] = React.useState(false)
  const [submitted, setSubmitted] = React.useState(false)
  const { showToast } = useContext(ToastContext)
  const handleSubmit = (event) => {
    event.preventDefault()
    if (!isValidEmail()) {
      setError(true)
      showToast("Please use a valid email", "error")
    } else {
      setError(false)
      sendEmail()
    }
    console.log(email)
  }
  const isValidEmail = () => {
    var re = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/
    if (!re.test(email)) return false
    return true
  }

  const onEmailBlur = (event) => {
    if (!isValidEmail()) {
      setError(true)
    } else {
      setError(false)
    }
  }

  const sendEmail = async () => {
    setSubmissionPending(true)
    try {
      fetch(`https://qftmx6.getshortstack.com/api/Phision`, {
        method: "POST",
        body: JSON.stringify({ email: email }),
      })
        .then((response) => {
          console.log(response)
          console.log(response.ok)
          if (response.ok === true) {
            showToast("You've signed up! We'll reach out shortly.", "success")
            setSubmitted(true)
            setSubmissionPending(false)
          } else {
            showToast(
              "An error ocurred submitting your email. Please try again.",
              "error"
            )
            setSubmitted(false)
            setSubmissionPending(false)
          }
        })
        .catch((e) => {
          showToast(
            "An error ocurred submitting your email. Please try again.",
            "error"
          )
          setSubmitted(false)
          setSubmissionPending(false)
        })
    } catch (error) {
      showToast(
        "An error ocurred submitting your email. Please try again.",
        "error"
      )
      setSubmitted(false)
      setSubmissionPending(false)
    }
  }

  return (
    <HeaderWrapper id="top">
      <Container>
        <Flex>
          <HeaderTextGroup>
            <Subtitle>Phision.AI</Subtitle>
            <h1>
              Stop wasting thousands
              <br />
              of dollars on pill counters
            </h1>
            <h2>Phision uses AI to count pills from your iPhone or Android!</h2>
            <HeaderForm onSubmit={handleSubmit}>
              {submitted ? (
                <>
                  <Subtitle>We'll reach out shortly!</Subtitle>
                  <HeaderButton
                    style={{ background: "hsl(180 22% 84% / 1)" }}
                    disabled
                  >
                    submitted{" "}
                    <span role="img" aria-label="emoji">
                      👌
                    </span>
                  </HeaderButton>
                </>
              ) : (
                <>
                  <HeaderInput
                    placeholder="Your email"
                    style={error ? { borderColor: "red" } : {}}
                    value={email}
                    error={error}
                    onBlur={onEmailBlur}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                  <HeaderButton>
                    {submissionPending ? (
                      <CircularProgress style={{ color: "#ffffff" }} />
                    ) : (
                      "Early access"
                    )}
                  </HeaderButton>
                </>
              )}
            </HeaderForm>
            {error ? <Subtitle>Please use a valid email</Subtitle> : <></>}
            {/* <FormSubtitle>
              Already have a beta account?{" "}
              <FormSubtitleLink to="/">Sign in</FormSubtitleLink>
            </FormSubtitle> */}
          </HeaderTextGroup>
          <ImageWrapper>
            <StyledImage fluid={data.file.childImageSharp.fluid} />
            <br />
          </ImageWrapper>
        </Flex>
      </Container>
    </HeaderWrapper>
  )
}

export default Header

const HeaderWrapper = styled.header`
  background-color: #f8f8f8;
  padding: 160px 0 80px 0;
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 5vw));
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
`
const Subtitle = styled.h5`
  font-size: 16px;
  color: ${(props) => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 16px;
`

const HeaderTextGroup = styled.div`
  margin: 0;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${(props) => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  h1 {
    margin: 0 0 24px;
    color: ${(props) => props.theme.color.primary};
  }

  h2 {
    margin-bottom: 24px;
    ${(props) => props.theme.font_size.regular}
  }

  p {
    margin-bottom: 48px;
  }
`

const Flex = styled.div`
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: 1fr 1fr;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 64px;
  }
`

const HeaderForm = styled.form`
  display: flex;
  flex-direction: row;
  padding-bottom: 16px;

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    flex-direction: column;
  }
`

// const FormSubtitle = styled.span`
//   ${(props) => props.theme.font_size.xxsmall}
// `

// const FormSubtitleLink = styled(Link)`
//   color: ${(props) => props.theme.color.secoyndary};
//   padding-bottom: 1px;
//   margin-left: 8px;
//   text-decoration: none;
//   border-bottom: 1px solid ${(props) => props.theme.color.secondary};
// `

const HeaderInput = styled.input`
  font-weight: 500;
  font-size: 16px;
  color: ${(props) => props.theme.color.primary};
  line-height: 42px;
  width: 100%;
  text-align: left;
  height: 60px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.color.secondary};
  border-image: initial;
  border-radius: 4px;
  padding: 8px 16px;
  outline: 0px;

  &:focus {
    box-shadow: inset ${(props) => props.theme.color.secondary} 0px 0px 0px 2px;
  }
  @media (max-width: ${(props) => props.theme.screen.md}) {
    margin-bottom: 8px;
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    display: block;
    width: 100%;
  }
`

const HeaderButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  height: 60px;
  display: block;
  margin-left: 8px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${(props) => props.theme.color.secondary};
  border-radius: 4px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    margin-left: 0;
  }
`
const ImageWrapper = styled.div`
  justify-self: end;
  align-self: center;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    justify-self: center;
  }
`

const StyledImage = styled(Img)`
  width: 500px;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    width: 400px;
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    width: 300px;
    display: none;
  }
`

import React, { useState, useCallback } from "react"
import Alert from "@material-ui/lab/Alert"
import { Snackbar } from "@material-ui/core"

// interface ToastState {
//   duration: number;
//   message: string;
//   severity: Color;
//   position: SnackbarOrigin;
// }

// interface ToastContextType {
//   showToast: (message: string, severity: Color) => void;
// }

export const ToastContext = React.createContext({
  showToast: (m, s) => null,
  //   showToast: (m: string, s: Color) => null,
})

const ToastContextProvider = (props) => {
  const [state, setState] = useState({
    message: "",
    severity: "info",
    duration: 8000,
    position: { vertical: "top", horizontal: "center" },
  })

  const showToast = useCallback(
    (
      message, //: string,
      severity, //: Color,
      position = { vertical: "top", horizontal: "center" }
    ) => setState((s) => ({ ...s, message, severity, position })),
    []
  )

  const handleClose = useCallback(
    () => setState((s) => ({ ...s, message: "" })),
    []
  )

  return (
    <ToastContext.Provider value={{ showToast }}>
      {props.children}
      <Snackbar
        open={!!state.message}
        autoHideDuration={state.duration}
        onClose={handleClose}
        anchorOrigin={state.position}
      >
        <Alert onClose={handleClose} severity={state.severity}>
          {state.message}
        </Alert>
      </Snackbar>
    </ToastContext.Provider>
  )
}

export default ToastContextProvider
